var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { lineBreakToParagraphAndHtml as LB2PAndH } from '../../../utils/browserUtils';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../../../utils/scroll-message-sender';
var styles = {
    rightArea: function (catchPosition) {
        if (catchPosition === void 0) { catchPosition = 'left'; }
        // CatchBlockの位置に応じてスタイルを変更
        if (catchPosition === 'center') {
            // メインキャッチが中央の場合：CatchBlockの上部に配置
            return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        position: absolute !important;\n        top: 90px;\n        left: 50% !important;\n        transform: translateX(-50%);\n        text-align: center;\n        max-width: 80%;\n        padding: 0 1rem;\n        z-index: 10;\n        @media screen and (min-width: 769px) {\n          width: 80%;\n          font-size: 1.2rem;\n        }\n        @media (max-width: 768px) {\n          position: static !important;\n          transform: none;\n          max-width: 100%;\n          width: 100%;\n          text-align: left;\n          margin-top: 1rem;\n        }\n      "], ["\n        position: absolute !important;\n        top: 90px;\n        left: 50% !important;\n        transform: translateX(-50%);\n        text-align: center;\n        max-width: 80%;\n        padding: 0 1rem;\n        z-index: 10;\n        @media screen and (min-width: 769px) {\n          width: 80%;\n          font-size: 1.2rem;\n        }\n        @media (max-width: 768px) {\n          position: static !important;\n          transform: none;\n          max-width: 100%;\n          width: 100%;\n          text-align: left;\n          margin-top: 1rem;\n        }\n      "])));
        }
        else if (catchPosition === 'right') {
            // メインキャッチが右の場合：左寄りに配置
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        position: absolute !important;\n        top: 8%;\n        right: auto;\n        left: 0 !important;\n        text-align: left;\n        max-width: 68%;\n        padding: 0 1rem;\n        @media screen and (min-width: 769px) {\n          position: absolute;\n          top: 12%;\n          width: 32%;\n          font-size: 1.2rem;\n        }\n        @media (max-width: 768px) {\n          text-align: left;\n        }\n      "], ["\n        position: absolute !important;\n        top: 8%;\n        right: auto;\n        left: 0 !important;\n        text-align: left;\n        max-width: 68%;\n        padding: 0 1rem;\n        @media screen and (min-width: 769px) {\n          position: absolute;\n          top: 12%;\n          width: 32%;\n          font-size: 1.2rem;\n        }\n        @media (max-width: 768px) {\n          text-align: left;\n        }\n      "])));
        }
        else {
            // メインキャッチが左の場合：右寄りに配置
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        position: absolute !important;\n        top: 8%;\n        right: 0;\n        left: auto !important;\n        text-align: right;\n        max-width: 68%;\n        padding: 0 1rem;\n        @media screen and (min-width: 769px) {\n          position: absolute;\n          top: 12%;\n          width: 32%;\n          font-size: 1.2rem;\n        }\n        @media (max-width: 768px) {\n          text-align: left;\n        }\n      "], ["\n        position: absolute !important;\n        top: 8%;\n        right: 0;\n        left: auto !important;\n        text-align: right;\n        max-width: 68%;\n        padding: 0 1rem;\n        @media screen and (min-width: 769px) {\n          position: absolute;\n          top: 12%;\n          width: 32%;\n          font-size: 1.2rem;\n        }\n        @media (max-width: 768px) {\n          text-align: left;\n        }\n      "])));
        }
    },
};
export var RightAreaTextBlock = function (_a) {
    var catchphrase = _a.catchphrase;
    if (catchphrase === null || catchphrase === void 0 ? void 0 : catchphrase.rightAreaText) {
        var rightAreaText = catchphrase.rightAreaText, rightAreaBgColor_1 = catchphrase.rightAreaBgColor, rightAreaBgOpacity_1 = catchphrase.rightAreaBgOpacity, rightAreaTextColor = catchphrase.rightAreaTextColor;
        var backgroundStyles = useMemo(function () {
            var red = parseInt(rightAreaBgColor_1.substring(1, 3), 16);
            var green = parseInt(rightAreaBgColor_1.substring(3, 5), 16);
            var blue = parseInt(rightAreaBgColor_1.substring(5, 7), 16);
            var alpha = rightAreaBgOpacity_1;
            return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        background-color: rgba(", ", ", ", ", ", ", ");\n      "], ["\n        background-color: rgba(", ", ", ", ", ", ", ");\n      "])), red, green, blue, alpha);
        }, [rightAreaBgColor_1, rightAreaBgOpacity_1]);
        var position = (catchphrase === null || catchphrase === void 0 ? void 0 : catchphrase.position) || 'left';
        return (_jsx(Box, __assign({ css: [
                backgroundStyles,
                styles.rightArea(position),
                scrollMessageSenderStyle(),
            ], onClick: createScrollMessageSender('catchphrase') }, { children: _jsx("div", __assign({ css: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            color: ", ";\n          "], ["\n            color: ", ";\n          "])), rightAreaTextColor) }, { children: LB2PAndH(rightAreaText) })) })));
    }
    else {
        return null;
    }
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

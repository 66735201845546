var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import Img from '../../../components/Img';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../../../utils/scroll-message-sender';
var styles = {
    img: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: none;\n    @media (max-width: 600px) {\n      display: block;\n    }\n  "], ["\n    display: none;\n    @media (max-width: 600px) {\n      display: block;\n    }\n  "]))),
    overlay: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: none;\n    @media (max-width: 600px) {\n      display: block;\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      pointer-events: none;\n    }\n  "], ["\n    display: none;\n    @media (max-width: 600px) {\n      display: block;\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      pointer-events: none;\n    }\n  "]))),
};
// 背景画像 (SP)
export var BgSpImageBlock = function (_a) {
    var bgSpImage = _a.bgSpImage, bgVideoOverlayColor = _a.bgVideoOverlayColor, bgVideoOverlayOpacity = _a.bgVideoOverlayOpacity;
    if (bgSpImage) {
        return (_jsxs("p", __assign({ className: 'w-full overflow-hidden relative', css: scrollMessageSenderStyle(), onClick: createScrollMessageSender('design-setting') }, { children: [_jsx(Img, { fetchpriority: 'high', css: styles.img, className: 'w-full h-full object-cover', src: bgSpImage, alt: '' }), bgVideoOverlayColor &&
                    bgVideoOverlayOpacity &&
                    bgVideoOverlayOpacity > 0 ? (_jsx("div", { css: styles.overlay, style: {
                        backgroundColor: bgVideoOverlayColor,
                        opacity: bgVideoOverlayOpacity,
                    } })) : null] })));
    }
    else
        return null;
};
var templateObject_1, templateObject_2;

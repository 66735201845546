var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import Img from '../../../components/Img';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../../../utils/scroll-message-sender';
var styles = {
    img: function (bgSpImage) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: block;\n    @media (max-width: 600px) {\n      display: ", ";\n    }\n  "], ["\n    display: block;\n    @media (max-width: 600px) {\n      display: ", ";\n    }\n  "])), bgSpImage ? 'none' : 'block'); },
    video: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    min-width: 100%;\n    min-height: 100%;\n    -webkit-transform: translate(-50%, -50%);\n    -moz-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n    object-fit: cover;\n    @media (max-width: 600px) {\n      position: static;\n      top: auto;\n      left: auto;\n      min-width: auto;\n      min-height: auto;\n      -webkit-transform: none;\n      -moz-transform: none;\n      transform: none;\n      object-fit: contain;\n    }\n  "], ["\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    min-width: 100%;\n    min-height: 100%;\n    -webkit-transform: translate(-50%, -50%);\n    -moz-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n    object-fit: cover;\n    @media (max-width: 600px) {\n      position: static;\n      top: auto;\n      left: auto;\n      min-width: auto;\n      min-height: auto;\n      -webkit-transform: none;\n      -moz-transform: none;\n      transform: none;\n      object-fit: contain;\n    }\n  "]))),
    videoContainer: function (headerFrag) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    width: 100%;\n    height: ", ";\n    overflow: hidden;\n    @media (max-width: 600px) {\n      height: auto;\n    }\n  "], ["\n    position: relative;\n    width: 100%;\n    height: ", ";\n    overflow: hidden;\n    @media (max-width: 600px) {\n      height: auto;\n    }\n  "])), headerFrag ? 'calc(100vh - 87px)' : '100vh'); },
};
// 背景画像 (PC)
export var BgImageBlock = function (_a) {
    var bgImage = _a.bgImage, bgVideo = _a.bgVideo, bgVideoOverlayColor = _a.bgVideoOverlayColor, bgVideoOverlayOpacity = _a.bgVideoOverlayOpacity, headerFrag = _a.headerFrag, bgSpImage = _a.bgSpImage;
    if (bgVideo) {
        // 動画の表示
        return (_jsx("p", __assign({ className: 'w-full overflow-hidden relative', css: scrollMessageSenderStyle(), onClick: createScrollMessageSender('design-setting') }, { children: _jsxs("div", __assign({ css: styles.videoContainer(headerFrag) }, { children: [_jsx("video", { css: styles.video, src: bgVideo, autoPlay: true, muted: true, loop: true, playsInline: true, controls: false, disablePictureInPicture: true, disableRemotePlayback: true }), bgVideoOverlayColor &&
                        bgVideoOverlayOpacity &&
                        bgVideoOverlayOpacity > 0 ? (_jsx("div", { style: {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: bgVideoOverlayColor,
                            opacity: bgVideoOverlayOpacity,
                            pointerEvents: 'none',
                        } })) : null] })) })));
    }
    else if (bgImage) {
        // 動画がなく画像がある場合は画像を表示
        return (_jsxs("p", __assign({ className: 'w-full overflow-hidden relative', css: scrollMessageSenderStyle(), onClick: createScrollMessageSender('design-setting') }, { children: [_jsx(Img, { fetchpriority: 'high', css: styles.img(bgSpImage), className: 'w-full h-full object-cover', src: bgImage, alt: '' }), bgVideoOverlayColor &&
                    bgVideoOverlayOpacity &&
                    bgVideoOverlayOpacity > 0 ? (_jsx("div", { style: {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: bgVideoOverlayColor,
                        opacity: bgVideoOverlayOpacity,
                        pointerEvents: 'none',
                    } })) : null] })));
    }
    else
        return null;
};
var templateObject_1, templateObject_2, templateObject_3;
